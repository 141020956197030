<template>
    <div class="member" style="overflow:hidden;min-width: 1500px;">
        <div style="width: 1500px; margin: 108px auto">
            <div style="width: 100%; height: 60px; margin-bottom: 2px; background-color: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                <span class="hand op" style="font-size: 18px; font-weight: 600; line-height: 60px; margin-left: 30px" @click="goBack"><i class="el-icon-back"></i> 返回</span>
                <span style="font-size: 20px; font-weight: 600; line-height: 60px; margin-left: 40%">管理Room成员</span>
            </div>
            <div style="width: 1380px; height: auto;overflow:hidden; background-color: #FFFFFF; padding: 20px 60px">
                <div style="display: flex; justify-content: space-between">
                    <div style="font-size: 18px; display: flex">
                        <div class="hand op" :class="indexTags === 'all' ? 'blue-tags' : 'black-tags'" @click="indexTags = 'all'">全部成员</div>

                        <div class="hand op" :class="indexTags === 'manage' ? 'blue-tags' : 'black-tags'" @click="indexTags = 'manage'">管理人员</div>

                        <div class="hand op" :class="indexTags === 'inside' ? 'blue-tags' : 'black-tags'" @click="indexTags = 'inside'">内部成员</div>

                        <div class="black-tags hand op" @click="getFriendList"><i class="el-icon-circle-plus" style="margin-right: 2px"></i>邀请好友</div>

                        <div class="black-tags hand op" v-if="user.userType === 2" @click="getSubList"><i class="el-icon-circle-plus" style="margin-right: 2px"></i>邀请子账号</div>
                    </div>

                    <div>
                        <el-input style="width: 480px; height: 32px; margin-right: 12px" v-model="selectMember" placeholder="请输入关键词搜索..."><i slot="suffix" class="el-icon-search" style="padding: 8px 10px; font-size: 20px; font-weight: 600"></i></el-input>
                    </div>
                </div>

                <div style="padding: 20px 0; display: flex; flex-wrap: wrap">
                    <div class="card-content" v-if="indexTags === 'all' || indexTags === 'manage'">
                        <div style="display: flex; justify-content: center; margin-bottom: 12px">
                            <img class="head-ico" :src="room.user.headIco">
                            <div style="margin: auto 0; width: 80%">
                                <div style="font-size: 18px; font-weight: 600; margin-bottom: 2px">
                                    {{room.user.nickName}}
                                </div>
                                <div style="color: #AAAAAA; font-size: 14px">
                                  <span v-if="room.user.address.cityName">{{room.user.address.cityName[1]}}</span><span v-if="room.user.major && room.user.address.cityName"> | </span>{{room.user.major}}
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center; margin-right: 20px">
                            <div v-if="room.myLv === 4" class="hand op card-btn" style="color: #FF9800; border: 1px #FF9800 solid; margin-right: 20px">自己</div>
                            <div class="hand op card-btn" style="background-color: #FF9800; color: #FFFFFF;">创建者</div>
                        </div>
                    </div>
                    <div v-for="item in roomUserList.adminList" :key="item.id">
                        <div class="card-content" v-if="indexTags === 'all' || indexTags === 'manage'">
                            <div style="display: flex; justify-content: center; margin-bottom: 12px">
                                <img class="head-ico" :src="item.headIco">
                                <div style="margin: auto 0; width: 80%">
                                    <div style="font-size: 18px; font-weight: 600; margin-bottom: 2px">
                                        {{item.nickName}}
                                    </div>
                                    <div style="color: #AAAAAA; font-size: 14px">
                                      <span v-if="item.address.cityName">{{item.address.cityName[1]}}</span><span v-if="item.major && item.address.cityName"> | </span>{{item.major}}
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex; justify-content: center; margin-right: 20px">
                                <div v-if="room.myLv === 2 && item.uuid === user.uuid" class="hand op card-btn" style="color: #FF9800; border: 1px #FF9800 solid; margin-right: 20px">自己</div>
                                <div v-if="room.myLv === 1" class="hand op card-btn" style="color: #BDBFC1; border: 1px #BDBFC1 solid; margin-right: 20px" @click="cancelManager(item.uuid)">取消管理员</div>
                                <div class="hand op card-btn" style="background-color: #2970FF; color: #FFFFFF;">管理员</div>
                            </div>
                        </div>
                    </div>

                    <div v-for="item in roomUserList.userList" :key="item.id">
                        <div class="card-content" v-if="indexTags === 'all' || indexTags === 'inside'">
                            <div style="display: flex; justify-content: center; margin-bottom: 12px">
                                <img class="head-ico" :src="item.headIco">
                                <div style="margin: auto 0; width: 80%">
                                    <div style="font-size: 18px; font-weight: 600; margin-bottom: 2px">
                                        {{item.nickName}}
                                    </div>
                                    <div style="color: #AAAAAA; font-size: 14px">
                                      <span v-if="item.address.cityName">{{item.address.cityName[1]}}</span><span v-if="item.major && item.address.cityName"> | </span>{{item.major}}
                                    </div>
                                </div>
                            </div>
                            <div style="display: flex; justify-content: center; margin-right: 20px">
                                <div class="hand op card-btn" @click="removeRoom(item.uuid)" style="color: #BDBFC1; border: 1px #BDBFC1 solid; margin-right: 20px">移出Room</div>
                                <div v-if="room.myLv === 1" class="hand op card-btn" style="color: #2970FF; border: 1px #2970FF solid;" @click="setManager(item.uuid)">设为管理</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--邀请好友-->
        <el-dialog class="show-message" :visible.sync="showFriends" width="740px">
            <div style="display: flex;">
                <div class="contentType">
                    <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                    <span>{{room.name}}</span>
                </div>
                <div class="dialog-title">邀请好友</div>
            </div>

            <div class="message-divider">
                <el-divider></el-divider>
            </div>

            <div class="message-body">
                <div class="title-form">邀请列表好友<span style="font-size: 13px; color: #999999">（已接受邀请加入room的好友不会显示）</span></div>
                <div v-for="(item, index) in friendsList" :key="index">
                    <div class="list-title" @click="item.show = !item.show"><i :class="!item.show ? 'el-icon-caret-right' : 'el-icon-caret-bottom'" style="font-size: 17px; margin: 0 22px"></i>{{item.name}}（{{item.friendArr.length}}）</div>
                    <div style="display: flex; flex-wrap: wrap" v-if="item.show === true">
                        <div style="display: flex; justify-content: center;" class="list-content" v-for="(friend, indexs) in item.friendArr" :key="indexs">
                            <img class="head-ico" style="width: 40px; height: 40px; margin: auto 6px;" :src="friend.friendInfo.headIco">
                            <div style="margin: auto 0; width: 70%">
                                <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
                                    {{friend.friendInfo.nickName}}
                                </div>
                                <div style="color: #AAAAAA; font-size: 14px">
                                  <span v-if="friend.friendInfo.address.cityName">{{friend.friendInfo.address.cityName[1]}}</span><span v-if="friend.friendInfo.major && friend.friendInfo.address.cityName"> | </span>{{friend.friendInfo.major}}
                                </div>
                            </div>
                            <div v-if="friend.select === false" @click="friend.select = true" class="hand op list-btn" style="color: #FF9800; border: 1px #FF9800 solid">选择好友</div>
                            <div v-if="friend.select === true"  @click="friend.select = false" class="hand op list-btn" style="color: #FFFFFF; background-color: #FF9800">取消</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="padding-bottom: 10px">
                <div @click="joinFriends" class="hand op" style="width: 180px; height: 40px; margin: 20px auto; text-align: center; line-height: 40px; border-radius: 5px; background-color: #2970FF; color: #FFFFFF; font-size: 16px; font-weight: 600">一键邀请好友</div>
            </div>
        </el-dialog>

<!--        邀请子账号-->
        <el-dialog class="show-message" :visible.sync="showSub" width="740px">
            <div style="display: flex;">
                <div class="contentType">
                    <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                    <span>{{room.name}}</span>
                </div>
                <div class="dialog-title">邀请子账号</div>
            </div>

            <div class="message-divider">
                <el-divider></el-divider>
            </div>
            <div class="message-body">
                <div class="title-form">邀请机构子账号<span style="font-size: 13px; color: #999999">（已接受邀请加入room的子账号不会显示）</span></div>
                    <div style="display: flex; flex-wrap: wrap">
                        <div style="display: flex; justify-content: center;" class="list-content" v-for="(item, index) in subsList" :key="index">
                            <img class="head-ico" style="width: 40px; height: 40px; margin: auto 6px;" :src="item.headIco">
                            <div style="margin: auto 0; width: 70%">
                                <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
                                    {{item.nickName}}
                                </div>
                                <div style="color: #AAAAAA; font-size: 14px">
                                  <span v-if="item.address.cityName">{{item.address.cityName[1]}}</span><span v-if="item.major && item.address.cityName"> | </span>{{item.major}}
                                </div>
                            </div>
                            <div v-if="item.select === false" @click="item.select = true" class="hand op list-btn" style="color: #FF9800; border: 1px #FF9800 solid">选择好友</div>
                            <div v-if="item.select === true"  @click="item.select = false" class="hand op list-btn" style="color: #FFFFFF; background-color: #FF9800">取消</div>
                        </div>
                    </div>
            </div>
            <div style="padding-bottom: 10px">
                <div @click="joinSubs" class="hand op" style="width: 180px; height: 40px; margin: 20px auto; text-align: center; line-height: 40px; border-radius: 5px; background-color: #2970FF; color: #FFFFFF; font-size: 16px; font-weight: 600">一键邀请好友</div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    import Head from "../../components/common/Head";
    export default {
        name: "member",
        components: {Head},
        data() {
            return {

                indexTags: 'all',

                selectMember: '',

                friendsList: [],

                roomUserList: {},
                room: {
                    name:'',
                    user:{},
                },

                showFriends: false,

                showSub: false,
                subsList: []

            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },

        mounted() {
            this.getRoomUser();
            this.getRoom()
        },

        methods: {
            // 一键邀请好友
            joinFriends() {
                var params = {};
                params.roomId = this.$route.params.id;
                params.userList = [];
                for (const item of this.friendsList) {
                    for (const i of item.friendArr) {
                        if (i.select === true) {
                            params.userList.push(i.friendInfo.uuid)
                        }
                    }
                }
                params.userList = JSON.stringify(params.userList);
                this.newApi.inviteJoinRoom(params).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.showFriends = false;
                    }
                })
            },

            // 一键邀请子账号
            joinSubs() {
                var params = {};
                params.roomId = this.$route.params.id;
                params.userList = [];
                for (const item of this.subsList) {
                    if (item.select === true) {
                        params.userList.push(item.uuid)
                    }
                }
                params.userList = JSON.stringify(params.userList);
                this.newApi.inviteJoinRoom(params).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.showSub = false;
                    }
                })
            },


            // 从Room中移除成员
            removeRoom(uuid) {
                this.newApi.removeRoomUser({roomId: this.$route.params.id, uuid: uuid}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.getRoomUser()
                    }
                })
            },

            // 获取子账号列表
            getSubsList() {
                this.newApi.getRoomSubAccountList({"roomId":this.room.id}).then(res => {
                    for (const item of res.data) {
                        item.select = false;
                    }
                    this.subsList = res.data
                })
            },

            // 子账号弹窗
            getSubList() {
                this.showSub = true;
                this.getSubsList()
            },

            // 获取好友列表
            getFriendsList() {
                this.newApi.getMyFriendGroupList().then(res => {
                    for (const item of res.data) {
                        item.show = false
                        for (const friend of item.friendArr) {
                            friend.select = false
                        }
                    }
                    this.friendsList = res.data
                })
            },

            // 设为管理员
            setManager(uuid) {
                this.newApi.setRoomAdmin({roomId: this.$route.params.id, uuid: uuid}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.getRoomUser()
                    }
                })
            },

            // 取消管理员
            cancelManager(uuid) {
                this.newApi.cancelRoomAdmin({roomId: this.$route.params.id, uuid: uuid}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.getRoomUser()
                    }
                })
            },

            // 返回上一页
            goBack() {
                this.$router.go(-1)
            },

            // 获取邀请好友列表
            getFriendList() {
                this.showFriends = true;
                this.getFriendsList()
            },

            // 获取room用户列表
            getRoomUser(){
                this.newApi.getRoomUserList({
                    roomId: this.$route.params.id
                }).then(ret =>{
                    this.roomUserList = ret.data;
                })
            },

            // 获取room详细信息
            getRoom(){
                this.newApi.getRoomInfo({
                    roomId: this.$route.params.id
                }).then(ret =>{
                    this.room = ret.data;
                })
            },
        }


    }
</script>

<style scoped>
    .member .black-tags {
        width: 132px;
        height: 40px;
        border-radius: 20px;
        background-color: #E4E6EB;
        text-align: center;
        line-height: 40px;
        margin-right: 30px;
    }

    .member .blue-tags {
        width: 132px;
        height: 40px;
        border-radius: 20px;
        color: #2970FF;
        background-color: #E7F3FF;
        text-align: center;
        line-height: 40px;
        margin-right: 30px;
    }

    .member .card-content {
        width: 282px;
        height: 108px;
        padding: 20px;
        border-radius: 5px;
        margin-right: 20px;
        margin-bottom: 20px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .member .head-ico {
        width: 56px;
        height: 56px;
        margin-right: 10px;
        border-radius: 50%;
    }

    .member .card-btn {
        width: 96px;
        height: 28px;
        line-height: 28px;
        border-radius: 5px;
        text-align: center;
    }

    .member .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        margin: 0 auto;
        font-size: 20px;
    }

    .member .contentType {
        color: #031F88;
        height: 18px;
        line-height: 50px;
        font-weight: 600;
        position: absolute;
        left: 10px;
    }

    .member .message-body {
        min-height: 200px;
        padding: 20px;
    }

    .message-divider .el-divider--horizontal {
        margin: 0;
    }

    .member .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .member .list-title {
        width: 232px;
        height: 36px;
        font-size: 15px;
        line-height: 36px;
        font-weight: 600;
        margin-bottom: 10px;
        background-color: #F5F5F5;
        border: 1px #D4D6D9 solid;
    }

    .member .list-content {
        width: 308px;
        height: 64px;
        border: 1px #CED0D4 solid;
        margin: 0 10px 10px 0;
    }

    .member .list-btn {
        width: 78px;
        height: 24px;
        line-height: 24px;
        margin: auto 10px;
        border-radius: 5px;
        text-align: center;
    }

    /deep/ .el-dialog__header{
        padding: 0;
    }

    /deep/ .el-dialog__body{
        padding: 0;
    }

    /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
        background-color: #F0F2F5;
        border-radius: 30px;
        border: none;
    }
</style>
